import React from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { CopyUrlButton } from 'wix-ui-tpa';
import { Duplicate } from '@wix/wix-ui-icons-common/on-stage';
import { classes, st } from './VideoConference.st.css';
import {
  ActionButtonProps,
  ActionButtonUiTypes,
  BookingDTO,
} from '../../../../../types';
import Link from '../../../Components/Link';
import {
  ActionsButtonsDataHooks,
  VideoConferenceDataHooks,
} from '../../datahooks';

export interface VideoConferenceProps extends ActionButtonProps {
  booking: BookingDTO;
}

export const VideoConference: React.FC<VideoConferenceProps> = ({
  booking,
  type,
}) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const link = booking.videoConference?.link;
  let content;

  if (!link) {
    return null;
  }

  if (type === ActionButtonUiTypes.LINK) {
    content = (
      <div className={st(classes.root, { isRTL })}>
        <Link
          className={classes.conferenceLink}
          data-hook={ActionsButtonsDataHooks.JoinVideoConferenceLink}
          onClick={() => {
            window.open(link, '_blank');
          }}
        >
          {t('app.my-bookings-widget.join-video-conference.link.label')}
        </Link>
        <CopyUrlButton
          url={link}
          data-hook={ActionsButtonsDataHooks.JoinVideoConferenceLinkCopy}
          tooltipText={t(
            'app.my-bookings-widget.join-video-conference.copy.tooltip',
          )}
          successText={
            isMobile
              ? t(
                  'app.my-bookings-widget.join-video-conference.copy.success-text',
                )
              : ''
          }
          className={classes.copyButton}
          icon={<Duplicate className={classes.icon} size={24} />}
        />
      </div>
    );
  }

  return <div data-hook={VideoConferenceDataHooks.Main}>{content}</div>;
};
