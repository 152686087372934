import React, { ReactElement } from 'react';
import {
  BookingsGroup,
  SelectedTab,
  TabTypes,
  ActionButtonUiTypes,
} from '../../../../../../types';
import { Accordion, Badge, BadgePriority } from 'wix-ui-tpa/cssVars';
import { classes, st } from './BookingDetails.st.css';
import { TFunction } from '@wix/yoshi-flow-editor';
import { ManageButton } from '../../../ManageButton';
import { BookingDetailsDataHooks } from '../../../datahooks';
import {
  isAvailableOnline,
  isBookAnotherAble,
  isPendingApproval,
  isWaitList,
} from '../../../../../../utils/bookInfo';
import { BookAgain } from '../../../ActionsButtons/BookAgain';
import { VideoFillSmall } from '@wix/wix-ui-icons-common/on-stage';
import BookingBodyContent from './BookingBodyContent';

export interface BookingDetailsProps {
  group: BookingsGroup;
  t: TFunction;
  isMobile: boolean;
  isRTL: boolean;
  selectedTab: SelectedTab;
  title: string;
  subtitle: string;
  isManageButtonOpen?: boolean;
}

export const renderBookingDetails = ({
  group,
  t,
  isMobile,
  isRTL,
  selectedTab,
  title,
  subtitle,
  isManageButtonOpen,
}: BookingDetailsProps): ReactElement => {
  const { bookings } = group;
  const firstBooking = bookings[0];

  const renderPendingApprovalBadge = () => {
    if (selectedTab.type === TabTypes.UPCOMING && isPendingApproval(group)) {
      return (
        <div>
          <Badge
            className={classes.badge}
            data-hook={BookingDetailsDataHooks.PendingApprovalBadge}
          >
            <>{t('app.my-bookings-widget.bookings-details.pending-approval')}</>
          </Badge>
        </div>
      );
    }
    return null;
  };

  const renderWaitingListBadge = () => {
    if (selectedTab.type === TabTypes.UPCOMING && isWaitList(group)) {
      return (
        <div>
          <Badge
            className={classes.badge}
            data-hook={BookingDetailsDataHooks.WaitlistBadge}
          >
            <>{t('app.my-bookings-widget.bookings-details.waitlist')}</>
          </Badge>
        </div>
      );
    }
    return null;
  };

  const renderAvailableOnlineBadge = () => {
    if (selectedTab.type === TabTypes.UPCOMING && isAvailableOnline(group)) {
      return (
        <div>
          <Badge
            className={classes.badge}
            priority={BadgePriority.light}
            data-hook={BookingDetailsDataHooks.AvailableOnlineBadge}
            icon={<VideoFillSmall aria-hidden="true" />}
          >
            <>{t('app.my-bookings-widget.bookings-details.available-online')}</>
          </Badge>
        </div>
      );
    }
  };

  const renderButton = () => {
    if (selectedTab.type === TabTypes.UPCOMING) {
      return (
        <div data-hook={BookingDetailsDataHooks.ManageButton}>
          <ManageButton group={group} isManageButtonOpen={isManageButtonOpen} />
        </div>
      );
    }

    if (selectedTab.type === TabTypes.HISTORY && isBookAnotherAble(group)) {
      return <BookAgain group={group} type={ActionButtonUiTypes.BUTTON} />;
    }

    return null;
  };

  const renderSuffix = () => {
    return (
      <div className={st(classes.suffix, { isMobile, isRTL })}>
        {renderPendingApprovalBadge()}
        {renderWaitingListBadge()}
        {renderAvailableOnlineBadge()}
        {renderButton()}
      </div>
    );
  };

  return (
    <Accordion.Item
      key={firstBooking.bookingId}
      data-hook={BookingDetailsDataHooks.Main}
      id={firstBooking.bookingId}
      title={title}
      subtitle={subtitle}
      suffix={renderSuffix()}
    >
      <BookingBodyContent group={group} />
    </Accordion.Item>
  );
};
